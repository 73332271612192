import { render, staticRenderFns } from "./app__media.vue?vue&type=template&id=d04fcfa8&lang=pug&"
import script from "./app__media.vue?vue&type=script&lang=js&"
export * from "./app__media.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppYoutube: require('/co2unt/services/customer-app/components/app/app__youtube/app__youtube.vue').default,AppImage: require('/co2unt/services/customer-app/components/app/app__image/app__image.vue').default,AppVideo: require('/co2unt/services/customer-app/components/app/app__video/app__video.vue').default})
