
export default {
  name: 'AppMedia',
  props: {
    item: {
      type: Object,
      required: true,
      media: {
        type: Object,
        required: true,
        filename_disk: {
          type: String,
          required: true
        },
        title: {
          type: String
        }
      }
    },
    classProp: {
      type: String
    }
  },
  computed: {
    isNotEmpty() {
      return this.item.youtube || this.isMediaAnImage || this.isMediaAVideo
    },
    isMediaAnImage() {
      return this.item.media?.type?.includes('image')
    },
    isMediaAVideo() {
      return this.item.media?.type?.includes('video')
    }
  }
}
