
export default {
  name: 'SectionContentDefault',
  props: {
    item: {
      type: Object,
      required: false,
      call_to_action_link: {
        type: Object,
        id: {
          type: String
        }
      }
    },
    translation: {
      type: Object,
      required: false,
      call_to_action_label: {
        type: String,
        required: false
      },
      optional_headline: {
        type: String,
        required: false
      },
      text: {
        type: String
      }
    }
  },
  computed: {
    callToActionLabel() {
      return this.translation?.call_to_action_label
    },
    callToActionLinkPath() {
      if (this.shouldRenderCallToActionLink)
        return this.$store.getters['store__app__routes/getCmsRouteFromPageId'](this.callToActionLinkId)?.path
    },
    callToActionLinkId() {
      return this.item?.call_to_action_link?.id
    },
    shouldRenderCallToActionLink() {
      return !!this.callToActionLabel && !!this.callToActionLinkId
    },
    shouldRenderMedia() {
      return !!this.item.media?.id
    },
    shouldRenderText() {
      return !!this.translation?.text && !!this.translation?.optional_headline
    }
  }
}
