import { render, staticRenderFns } from "./section__content-default.vue?vue&type=template&id=3c49d6c2&scoped=true&lang=pug&"
import script from "./section__content-default.vue?vue&type=script&lang=js&"
export * from "./section__content-default.vue?vue&type=script&lang=js&"
import style0 from "./section__content-default.vue?vue&type=style&index=0&id=3c49d6c2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c49d6c2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppMedia: require('/co2unt/services/customer-app/components/app/app__media/app__media.vue').default})
