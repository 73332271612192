
export default {
  name: 'AppYoutube',
  props: {
    youtube: {
      type: String,
      required: true
    }
  },
  computed: {
    youtubeEmbedUrl() {
      let ID = this.youtube
      let url = this.youtube.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i)
        ID = ID[0]
      }
      return `https://www.youtube.com/embed/${ID}`
    }
  }
}
